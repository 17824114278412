.team_image {
  float: left;
  margin-right: 20px;
}

.research-page-body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

// .introduction,
.research-style {
  background: #ffffff;
  color: #222222;
  height: 100%;
  text-align: left;
  padding-top: 10vh;
  margin-top: 60px;
  height: calc(100vh - 60px);  // Fill remaining viewport height
  overflow-y: auto;  // Enable vertical scrolling

  ul {
    list-style-type: disc; // This adds the dot before each list item
    padding-left: 20px; // Ensures indentation of list items
  }

  .subpage-heading {
    font-family: "Arial";
    font-size: 32px;
    color: #096278;
  }

  .research-subheading p {
    font-family: "Arial";
    font-size: 20px;
    color: #333333;
    margin-bottom: 50px;
    margin-left: 0;
  }

  .research-subheading {
    font-family: "Arial";
    font-size: 20px;
    color: #096278;
    margin-left: 0;
  }

  .research-subheading img {
    width: 350px; /* Fixed width */
    height: 300px; /* Fixed height */
    object-fit: cover; /* This ensures the image covers the area without losing its aspect ratio. */
  }

  p {
    font-family: "Arial";
    font-size: 20px;
    color: #333333;
    line-height: 1.45;
  }
}

/*-------------- footer style -----------------*/

.intro-container {
  min-height: 10vh;
  display: flex;
  margin: 0 auto;
  width: 80%;
  flex-direction: column;
  margin-top: 5vh;
  flex: 1;
  //height: calc(100vh - 60px);  // Fill remaining viewport height
  overflow-y: auto;  // Enable vertical scrolling

  .introduction {
    font-family: "Arial";
    font-size: 20px;

    .subpage-heading {
      color: #096278;
    }
  }
}

.social-link {
  color: #096278;
  text-decoration: none;
  font-weight: bold;
}
@media screen and (max-width: 500px) {
  .masthead {
    padding: 3em 0;
  }
  .masthead-heading {
    font-size: 3em;
  }
}

@media (min-width: 992px) {
  .navbar-expand-custom {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-custom .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-custom .navbar-toggler {
    display: none;
  }
  .navbar-expand-custom .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}

@media (max-width: 991px) {
  #navbarSupportedContent ul li a {
    padding: 12px 30px;
  }
  .hori-selector {
    margin-top: 0px;
    margin-left: 10px;
    border-radius: 0;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .hori-selector .left,
  .hori-selector .right {
    right: 10px;
  }
  .hori-selector .left {
    top: -25px;
    left: auto;
  }
  .hori-selector .right {
    bottom: -25px;
  }
  .hori-selector .left:before {
    left: -25px;
    top: -25px;
  }
  .hori-selector .right:before {
    bottom: -25px;
    left: -25px;
  }
}
/*-------------- research page style -------------*/

.research-page {
  width:100%;

}


.research-style {
  padding-left: 10vw;
  padding-right: 10vw;
  min-height: 100vh;
  flex: 1 0 auto;
}

.front-page-footer {
  position: relative;
  bottom: 15vh;
  left: 0;
  right: 0;
  //width: 100%;
  margin-top: 40vh;
}

.front-page-footer-intro {
  position: relative;
  bottom: 200px;
  left: 0;
  right: 0;
  //width: 100%;
  margin-top: 300px;
}


.research-subheading {
  img {
    float: left;
    margin-right: 15px;
    width: 25%;
    border-radius: 8px;

  }

  h3 {
    margin-bottom: 20px;
  }

  h4 {
    margin-bottom: 20px;
    color: #6f8d94;
    margin-bottom: 1vh;
  }
}

.title {
  margin-bottom: 2vh;
  color: #096278;
}

/*-------------- resource bank style -------------*/
.resource-page {
  padding-left: 20em;
  padding-right: 20em;
  margin-top: 10vh;
}

.masthead-heading {
  margin-top: -0.2em;
  color: #e9e1d6;
  font-family: "Verdana";
  font-style: normal;
  font-weight: bold;
  font-size: 3em;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  margin-left: 80px;
  margin-right: 80px;
}

images .inlineImage {
  float: right;
  padding: 20px;
  margin-left: 15px;
  margin-bottom: 15px;
  width: 45%;
  height: auto;
}

.paragraph-bg-1 {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 30px;
}

.paragraph-bg-2 {
  padding: 25px;
  border-radius: 30px;
  background-image: url("../assets/bedroom.jpg");
}


//