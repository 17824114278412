@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Phenomena";
  src: url("../src/assets/fonts/Phenomena-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bradley Hand";
  src: url("../src/assets/fonts/BradleyHandITCTT-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

$colors: (
  dark-blue: rgba(21, 33, 51, 255),
  light-background: #dad8d6,
  aqua: #79ccd5,
);

#buttonid::-moz-focus-inner,
.buttonclass::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0 !important;
  border: 0 none !important;
}

.main-page {
  position: relative;
  text-align: center;
  line-height: 1.45;
  background: map-get($colors, dark-blue);
  background-size: cover;
  color: rgb(255, 255, 255);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100vh;

 
}

.header {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  z-index:100;  
  position:fixed;


}

.signup-header {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  z-index:100;  
  //position:fixed;


}

.header-intro {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px; /* Example fixed height */
  z-index: 1000; 
}

/*---------- the get it start button ----------*/
.chatButton-default {
  position: absolute;
  bottom: 10%;
  display: "flex";
}

.chatButton-login {
  position: relative;
  right: 7%;
  bottom: 25%;
  display: flex;
}

.container .btn-holder {
  position: relative;
  text-align: center;
  padding-right: 0;
  padding-top: 0;
  vertical-align: middle;
  display: inline-block;
  margin-top: 3em;
  max-width: 5vh;
  min-width: 1vh;
}

/*---------- font page header settings ----------*/
.main-container {
  // position: relative;
  // height: auto;
  // bottom: -145px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  flex-direction: row;
  width: 100%;

  .inner-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;
    position: relative;
  }

  .face-photo {
    position: absolute;
    height: auto;
    margin-left: 10vh;
    max-width: 60vh;
    min-width: 30vh;
    margin-top: calc(-5vh);
  }

  .title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 80vh;
    margin-top: -10vh;
  }

  #maintitle {
    font-family: "Phenomena";
    font-size: 200px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
   // margin-top: -15vh;
  }

  #subtitle {
    font-family: "Bradley Hand";
    text-align: left;
    font-size: 35px;
    float: center;
    width: 20em;
    //margin-left: 2vh;
    color: #f7ffc7;
  }

  .chatButton-container-main {
    position: relative;
    height: auto;
    bottom: -25vh;
    max-width: 10%;
    min-width: 5%;
    width: auto;
    z-index: 1000;
  }
}

/* Media query for screen sizes less than 600px wide */
@media only screen and (max-width: 600px) {
  .chatButton-default {
    bottom: 0%;
  }
  .face-photo {
    width: 50%;
    margin-left: 5vh;
    margin-top: -2.5%;
    max-width: 200px;
    min-width: 100px;
  }
}

/*----------button position ----------*/
.pulse-button {
  position: relative;
  width: 20%;
  // max-width: 35vh;
  // min-width: 1vh;
  max-height: 10vw;
  min-height: 3vw;
  margin-top: -8em;
  display: block;
  width: 20em;
  font-size: 100%;
  font-weight: bold;
  font-style: normal;
  font-family: "Verdana";
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  color: #096278;
  border: none;
  border-radius: 40%;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.596);
  /* animation: pulse 2s infinite; */
}

.pulse-button:hover {
  transform: scale(1.05);
  -webkit-animation: pulse 2s infinite;
}

p {
  color: #333333;
  text-align: justify;
}

/*---------- frontpage intro blocks ----------*/

.blocks-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #dad8d6;

  .block {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px;

    .text-area {
      
      text-align: left;
      color: black;
      font-size: 24px;
      font-family: Arial;
      width: 700px; 
      max-width: 100%;
      margin-right: 30px; 
      margin-left: 30px;
    }

    img {
      // width: 30%;
      // height: auto;
      width: 500px;  // Smaller width for smaller screens
      height: 450px;
      border-radius: 8px;
    }
  }

  .block-profile {
    background-color: #ffffff;
    flex-direction: row;
    padding-left: 10vh;
    padding-right: 10vh;
  }

  .block-memory {
    background-color: #ffffff;
    flex-direction: row-reverse;
    padding-left: 10vh;
    padding-right: 10vh;
  }

  .block-strategy {
    background-color: #ffffff;
    flex-direction: row-reverse;
    padding-left: 10vh;
    padding-right: 10vh;
  }
}

/*---------- Animation wave ----------*/

@keyframes waves {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.waves {
  position: relative;
  width: 100%;
  height: 3vh;
  margin-bottom: 0 px;
  min-height: 100px;
  margin-top: -45em;

  .parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
}

/*---------- front page footer ----------*/

// .front-page-footer {
//   flex-shrink: 0;
// }
.front-footer {
  position: relative;
  //height: 15vh;
  text-align: center;
  // background-color: white;
  flex-direction: column;
  font-family: "Arial";
  font-size: 18px;
  padding-top: 5vh;
  background-color: #1e2246;

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1vh;

    .footer-link {
      margin-bottom: 0.5vh;
      color: #ffffff;
      text-decoration: none;
    }
  }

  .footer-column {
    display: flex;
    flex-direction: column;
    margin-right: 10vw;
  }

  .footer-column a {
    margin-bottom: 0.5vh;
    color: rgb(255, 255, 255);
  }

  .footer-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 4vh;
    height: 5vh;
    margin-bottom: 3vh;
    // padding-bottom: 1px;

    p {
      color: #aaaaad;
    }
  }

  .footer-logo {
    display: flex;
    align-items: center;
  }

  .footer-logo img {
    //height: 10vh; /* Adjust the height as needed */
    height: 80px;
    margin-right: 400px;
  }
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/* Standard keyframes for non-WebKit browsers */
@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(245, 244, 244, 0.963);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 5px 15px rgba(254, 254, 254, 0.95);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 5px 15px rgba(255, 255, 255, 0.933);
  }
}

/*pay attention to whether this is css style, not scss*/
@-webkit-keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(245, 244, 244, 0.963);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 5px 15px rgba(254, 254, 254, 0.95);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 5px 15px rgba(255, 255, 255, 0.933);
  }
}

/*----------Shrinking for mobile (the site is deactivated for mobile, this session can be removed later)----------*/
@media (max-width: 600px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }

  .pulse-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

* {
  margin: 0;
  padding: 0;
}
i {
  margin-right: 10px;
}
/*----------bootstrap-navbar-css------------*/

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  #navbarSupportedContent li .nav-link {
    // position: fixed;
    background: "transparent";
    font-size: 16px;
    color: #096278;
    font-family: Verdana;
    font-weight: bold;
    display: block;
    padding: 20px 20px;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    cursor: pointer;
    border: none;
    text-decoration: none;
  }

  .active {
    background-color: #79ccd5;
  }

  li {
    float: left;
  }

  li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }

  li a:hover:not(.active) {
    background-color: #79ccd5;
  }

  .nav-item {
    padding: auto;
  }

  #navbarSupportedContent {
    overflow: hidden;
    position: relative;
  }
  #navbarSupportedContent ul {
    padding: 0px;
    margin: 0px;
  }
  #navbarSupportedContent ul li a i {
    margin-right: 10px;
  }
  #navbarSupportedContent li {
    list-style-type: none;
    float: left;
  }
  #navbarSupportedContent ul li a {
    color: #096278;
    text-decoration: none;
    font-size: 15px;
    font-family: "Verdana";
    font-weight: bold;
    display: block;
    padding: 20px 20px;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  #navbarSupportedContent > ul > li.active > a {
    color: #30c8d9;
    background-color: transparent;
    transition: all 0.7s;
  }
  #navbarSupportedContent a:not(:only-child):after {
    content: "\f105";
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 14px;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    transition: 0.5s;
  }
  #navbarSupportedContent .active > a:not(:only-child):after {
    transform: rotate(90deg);
  }
}

@media (min-width: 992px) {
  .navbar-expand-custom {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-custom .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-custom .navbar-toggler {
    display: none;
  }
  .navbar-expand-custom .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}

@media (max-width: 991px) {
  #navbarSupportedContent ul li a {
    padding: 12px 30px;
  }
}

/*----------plan page------------*/
.buy-credit {
  text-align: center;

  font-size: 3vh;
  background-color: #1e2246;
  min-height: 100vh;
  font-family: "Arial";

  .buy-credit-content {
    // margin-top: 10vh;

    .tab-buttons {
      margin-bottom: 20px;
      margin-top: 10vh;
    }

    .tab-buttons button {
      padding: 5vh 10vh;
      margin-right: 10px;
      font-size: 16px;
      background-color: #f0f0f0;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 20px;
    }

    .tab-buttons button.active {
      background-color: #007bff;
      color: #fff;
    }

    .buy-button {
      padding: 2vh 4vh;
      font-size: 20px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 10vh;
    }
  }
}

.assign-credit {
  text-align: center;

  font-size: 3vh;
  background-color: #1e2246;
  min-height: 100vh;
  font-family: "Arial";

  .buy-credit-content {
    // margin-top: 10vh;

    .api-containter {
      margin-top: 10vh;
    }

    .tab-buttons {
      margin-bottom: 20px;
      margin-top: 20vh;
    }

    .tab-buttons button {
      padding: 5vh 10vh;
      margin-right: 10px;
      font-size: 16px;
      background-color: #f0f0f0;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 20px;
    }

    .tab-buttons button.active {
      background-color: #007bff;
      color: #fff;
    }

    .buy-button {
      padding: 2vh 4vh;
      font-size: 20px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 10vh;
    }
  }
}


#tidio-chat-iframe  {

  //inset: auto 9px 35px auto !important;
 inset: auto 1vw -1vh auto !important;

}

