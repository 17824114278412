// :root {
//   --body-bg: linear-gradient(
//     100deg,
//     #07b2d9,
//     #30c8d9 40%,
//     #f2dec4 100%
//   ) !important;

//   --msger-bg: #fff;
//   --border: 2px solid #ddd;
//   --left-msg-bg: #ececec;
//   --right-msg-bg: #22a699;
// }

//  --body-bg: linear-gradient(-225deg, #ffcb9f, #e1acb3, #bc9dd3, #89c7bd 

:root {

  // --body-bg: linear-gradient(-135deg, #ffcb9f, #e1acb3, #bc9dd3, #89c7bd 
  // ) !important;  //plan1

  

  // --body-bg: linear-gradient(to top, #241b42, #1fcaf7 70%, #1ae5e4, #f9d194 , #44d5c6) !important; //plan 2
  
  // --border: 2px solid #ddd;
  --left-msg-bg: radial-gradient(circle at bottom left,  #ebeed5, #f8d8da); //plan 1
  --right-msg-bg: radial-gradient(circle at bottom left, #874CCC, #667BC6); //plan 1
  // --left-msg-bg: radial-gradient(circle at bottom left,  #f4edd6, #e5e5e5); //plan 2
  // --right-msg-bg: radial-gradient(circle at bottom left, #593f41, #b17464); //plan 2
}


.body_chat {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: var(--body-bg);
  font-family: "Arial";
}

.chatbot-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  overflow: hidden;

  .style-button-containter {
    position: relative;
    font-size: 14px;
    text-align: center;
    bottom: 9vh;
    z-index: 1050;
  }

  .toggle-container {
    position: fixed;
    width: 80px;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 10px;
    right: -145vh;
    bottom: -10vh;
    overflow: hidden;
    font-family: "Arial";
    transition: background-color 0.3s;

    &.mode-compact {
      background-color: #c8d0dd;
    }

    &.mode-wide {
      background-color: #29b5a8;
    }

    .label-left,
    .label-right {
      position: absolute;
      font-weight: bold;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      font-size: 14px;
      font-family: "Arial";
    }

    .label-left {
      left: 10px;
    }

    .label-right {
      right: 10px;
    }
  }


  .toggle-theme-btn {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: white;
    border: none;
    border-radius: 50%;
    transition: 0.3s;
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.compact {
      left: calc(100% - 60px);
    }

    &.wide {
      left: calc(100% - 20px);
    }
  }
}

.chatbot-page::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/chatbot_bg3.jpg'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.7; 
  pointer-events: none; 
  transform: scaleX(-1);
}

.chatbot-page > * {
  position: relative; /* Ensures content is above the background */
  z-index: 1; /* Higher z-index to keep content above the pseudo-element */
}

.msger.compact {
  max-width: 800px;
  margin: 50px auto;
}
.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  height: calc(100% - 100px);
  width: 100%;
  max-width: 1200vh;
  margin: 50px 130px;
  max-height: 80vh;
  border: var(--border);
  border-radius: 5px;
  background: var(--msger-bg);
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  flex: 1;

  /* New .compact class adjusting the .msger styling for compact mode */

  .msger-header {
    font-size: medium;
    justify-content: space-between;
    padding: 10px;
    text-align: center;
    border-bottom: var(--border);
    background: #eee;
    color: #07b2d9;
    font-family: "Arial";
    font-size: 18px;
  }
  .msger-chat {
    flex: 1;
    overflow-y: auto;
    padding: 10px;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #f1e6fa;
    }
    &::-webkit-scrollbar-thumb {
      background: #bc9dd3;
    }
  }
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  // border: none;
  // background: #eee;
  background:transparent;
  font-family: "Arial";
  justify-content: space-between;

  .msger-input {
    flex: 1;
    padding: 10px;
    resize: none;
    overflow-y: auto;
    border-radius: 10px;
    height: 35px;
  }

  .msger-send-btn {
    margin-left: 10px;
    margin-right: 10px;
    background: radial-gradient(circle at bottom left, #874CCC, #667BC6);
  // background: radial-gradient(circle at bottom left, #593f41, #b17464);
    color: #fff;
    cursor: pointer;
    transition: background 0.23s;
    font-weight: bold;
    height: 55px;
    padding: 10px 10px;
    border: none;
    border-radius: 8px;

  }
}

.session-complete-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 30%; /* Take the full height of the parent or as needed */
  gap: 40px;
}

.session-complete-btn {
  // margin-left: 50px;
  // margin-right: 10px;
  background: #22a699;
  color: #fff;
  cursor: pointer;
  transition: background 0.23s;
  height: 55px;
  padding: 10px 10px;
  border: none;
  font-weight: bold;
  border-radius: 8px;
}

.session-action-btn {
  background: #22a699;
  color: #fff;
  cursor: pointer;
  transition: background 0.23s;
  height: 55px;
  padding: 10px 10px;
  border: none;
  border-radius: 8px;
  font-weight: bold;
}

/*------------- message bubble style ---------- */
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;

  .msg-img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
  }
}

.msger-chat {
  // background: linear-gradient(135deg, #ffcb9f, #e1acb3, #bc9dd3, #89c7bd);
  background: transparent;
}

.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: var(--left-msg-bg);
  margin-top: 20px;
  font-family: "Arial";
  font-size: 20px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);

  .msg-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .msg-info-name {
    margin-right: 10px;
    font-weight: bold;
  }
  .msg-info-time {
    font-size: 0.85em;
  }
}

.msger.compact .msg-bubble {
  font-size: 16px;
}

.right-msg {
  flex-direction: row-reverse;

  .msg-bubble {
    background: var(--right-msg-bg);
    color: #ffffff;
    border-bottom-right-radius: 0;
  }

  .msg-img {
    margin: 0 0 0 10px;
  }
}

/*------------- api input area ---------- */
.api-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #eee;

  * {
    padding: 10px;
    width: 250px;
    border: none;
    border-radius: 3px;
    font-size: 1em;
    font-family: "Arial";
  }

  .api-input {
    flex: 1;
    background: #ddd;
  }

  .api-send-btn {
    margin-left: 10px;
    background: #0678bf;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.23s;
  }
  .api-send-btn:hover {
    background: #30c8d9;
  }
}

/*------------- agreement component ---------- */

.agree-tacbox {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.indented-list {
  padding-left: 5vh;
}

.agree-tacbox p,
.agree-tacbox ul {
  margin-bottom: 10px;
}

.checkbox-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.checkbox-container input[type="checkbox"] {
  margin-top: 4px;
  flex-shrink: 0;
}

.checkbox-container label {
  margin-left: 20px;
  flex-grow: 1;
  word-wrap: break-word;
  line-height: 1.5;
  margin-right: -30vh;
}

.agree-btn-container {
  text-align: center;
  margin-top: 20px;
}

.agree-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


/*  rating and feedback */
.ratings img {
  cursor: pointer;
  transition: all 0.5s;
  height: 20px;
  float: left;
}

.ratings img:hover {
  transform: scale(1.3);
}

.rating-img {
  font-size: 0;
}

.feedback-inputarea {
  margin-top: 5px;
}

.feedback-inputarea input {
  height: 20px;
  width: 40%;
}

.send-correct {
  margin-left: 5px;
  background: rgb(229, 227, 225);
  color: #0e0e0e;
  cursor: pointer;
  transition: background 0.23s;
  margin-bottom: 5px;
}

/*Defining the thumb button in feedback form */
.rating-img {
  height: 20px;
  width: auto;
}

.rating-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.rewrite-textarea {
  width: 500px;
  width: 90%;
}

/*------------- profile page, summary container -----------*/
.profile-background {
  // background: linear-gradient(100deg, #07b2d9, #30c8d9 40%, #f2dec4 100%);
  background: #171c3e;
  min-height: 70vh;
  overflow-x: hidden;
  // overflow: auto;

  .wait-list-text p {
    position: absolute; /* Change to absolute */
    top: 50%; /* Change from bottom to top */
    left: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    font-size: 25px;
    font-family: "Arial";
    text-align: center;
    color: #ffffff;
  }

  .summary-form-container {
    margin-top: 5vh;
    max-width: 1400px;
    //height: 400vh;
    min-height: 200px;
    overflow-y: auto;
    margin-left: auto; /* Centers the container horizontally */
    margin-right: auto;

    p {
      color: #bdbdbd;
    }

    .summary-container {
      margin: 10px;
      margin-top: auto;
      margin-bottom: 10px;
      // margin-left: 100px;
      // margin-right: 600px;
      // border: 1px solid #ffe0d1;
      border-radius: 5px;
      padding: 30px;
      background-color: #1e2246;

      .summary-header {
        cursor: pointer;
        font-weight: bold;
        font-family: "Arial";
      }
    }
    .summary-text {
      font-size: 16px;
      margin-top: 3vh;
      margin-left: 2vh;
      font-family: "Helvetica";
      color: #cac8c8;
      line-height: 2.5vh;
    }

    .summary-time {
      color: #ffffff;
    }

    p {
      font-size: 20px;
      margin-left: 75vh;
    }
  }

  .summary-btn-container {
    display: flex;
    justify-content: center;
    // align-items: left;
    gap: 50px;
    // margin-right: 700px;
    // margin-top: 50px;

    .summary-btn {
      margin-top: 100px;
      background: #4356d6;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      transition: background 0.23s;
      height: 45px;
      padding: 10px 10px;
      border-radius: 10px;
      border: none;
      box-shadow: none;
      font-family: "Arial";
    }
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-content {
    padding: 20px;
    background-color: #1e2246;
    color: #bdbdbd;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}

.credits-tab {
  // position: relative;
  margin-top: 20px;
  // margin-right: 200px;
  // margin-left: 1000px;
  background-color: #1e2246;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 200px;
  margin-left: auto; /* Centers the container horizontally */
  margin-right: auto;

  .font {
    color: white;
    font-size: 18px;
    line-height: 5vh;
    font-family: "Helvetica";
  }


  }
  
  .language-dropdown-container {
    position: fixed;
    top: 10px; // Adjust as per your header's height
    right: 20vw;
    z-index: 1000; // Ensure it's above other elements

    // .language-select-wrapper {
    //   display: flex; // Enables flexbox
    //   align-items: center; // Vertically centers the items in the container

      .language-label {
          margin-right: 10px; // Adds spacing between label and dropdown
          white-space: nowrap; // Keeps the label on a single line
          color: #1e2246;
          font-weight: bold;
      }
  
    .language-dropdown {
      padding: 5px 10px;
      border-radius: 5px;
      background-color: white;
      border: 1px solid #ccc;
    }
    
  
  }

  @media (max-width: 1400px) {
    .credits-tab {
      margin-left: 5vw; /* Adjust left margin on smaller screens */
      margin-right: 10vw; /* Adjust right margin on smaller screens */
      font-size: 16px; /* Smaller font size for smaller screens */
    }

    .font {
      font-size: 16px; /* Adjust font size for smaller screens */
    }
  }

