//@import url(https://fonts.googleapis.com/css?family=Roboto:300);

.form-and-chat {
  flex-direction: column;
  align-items: center;
  display: flex;
  height: 100%;
  flex-grow: 1;
}

.login-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1e2246;
  padding-top: 60px;
}

.login-page {
  width: 380px;
  padding: 8% 0 0;
  margin: auto;
  flex-grow: 1;
  min-height: 80vh;
 
  

  .form .message {
    text-align: center;
  }

  /* Styles for "Forget my password" link */
  .forget-password-link {
    color: blue; /* Sets the link color to blue */
    display: block; /* Makes it a block-level element, aiding in center alignment */
    margin-top: 1rem; /* Optionally, adds some space above the link */
  }

  .terms-link {
    display: inline-block;
    background-color: white;
    color: blue;
    text-decoration: none;
    padding: 0.5em 1em;
    margin: 2vh;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
  }
  .form {
    position: relative;
    z-index: 1;
    background: #ffffff;
    max-width: 800px;
    width: 80%;
    margin: 0 auto 100px;
    padding: 40px;
    text-align: left;
    // box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
    font-family: "Arial";
    font-size: 16px;

    input {
      font-family: "Arial";
      outline: 0;
      background: #f2f2f2;
      width: 100%;
      border: 0;
      margin: 0 0 15px;
      padding: 15px;
      box-sizing: border-box;
      font-size: 14px;
    }
    button {
      font-family: "Roboto", sans-serif;
      text-transform: uppercase;
      outline: 0;
      background: #0669bf;
      width: 100%;
      border: 0;
      padding: 15px;
      color: #ffffff;
      font-size: 18px;
      -webkit-transition: all 0.3 ease;
      transition: all 0.3 ease;
      cursor: pointer;
      box-shadow: #040404;
    }
    button:hover,
    button:active,
    button:focus {
      background: #30c8d9;
    }

    button:disabled {
      background-color: #666; /* Darker grey */
      color: #ccc; /* Light grey text */
      cursor: not-allowed;
      border: 1px solid #444; /* Darker border */
    }
    .message {
      margin: 15px 0 0;
      color: #0669bf;
      font-size: 16px;
    }
    .message a {
      color: #e63d37;
      text-decoration: none;
      font-weight: bold;
    }
    .register-form {
      display: none;
    }
  }
}

.modal {
  // position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    //max-width: 100vh;
    width: 100%;
  }

  .modal-actions {
    text-align: right;
  }


  .button-container {
    // margin-right: 20vh;
    // margin-bottom: -3vh;
    margin-top: 2vh;

    .agree-button {
      background-color: #79ccd5;
      border-radius: 5px;
      border: none;
      width: 8vw;
      height: 5vh;
      font-family: "Arial";
      margin-right: 5vw;
    }
  
    .disagree-button {
      background-color: #fb7684;
      border-radius: 5px;
      border: none;
      width: 8vw;
      height: 5vh;
      font-family: "Arial";
      margin-right: 4vw;
    }
  
  }

  .termsCondition {
    overflow-y: scroll; /* Enables vertical scrolling */
    padding: 40px;
    font-family: "Arial";
    text-align: left;
    padding-top: 5vh;
    padding-left: 10vh;
    padding-right: 10vh;
    padding-bottom: 0vh;
  }
}

.privacy-policy,
.termsCondition {
  font-family: "Arial";
  display: flex;
  width: 80%;
  margin: auto;
  max-width: 1200px;
  flex-direction: column;
  overflow-y: hidden;
  min-height: 100vh;
  // max-height: 100vh;

  .headline {
    margin-top: 10vh;
    font-size: 40px;
    color: #162133;
  }
  .subheadline {
    font-size: 25px;
    color: #3d5a8a;
  }

  .subheadline2 {
    font-size: 20pv;
    font-size: 20px;
    color: rgb(65, 97, 147);
  }
  .application-list {
    color: #040404;
    // text-align: left;
  }
  .paragraph {
    font-size: 20px;
  }

  .paragraph-strong {
    font-size: 20px;
    font-weight:bold;
    color: #3d5a8a;
  }

  .underline-paragraph {
    text-decoration: underline;
  }

  ul {
    padding-left: 5vh;
  }

  li {
    font-size: 20px;
  }

  .data-table {
    width: 100%;
    border-collapse: collapse; /* Ensures that border lines between cells are single lines */
  }
  
  .data-table th, .data-table td {
    border: 1px solid #ddd; /* Sets the color and style of the border */
    padding: 8px; /* Adds space around text inside cells */
  }
  
  .data-table th {
    background-color: #f2f2f2; /* Light grey background for header cells */
    text-align: left; /* Aligns header text to the left */
  }
  
  .data-table tr:nth-child(even) {
    background-color: #f9f9f9; /* Zebra stripes for rows */
  }
  
  .data-table tr:hover {
    background-color: #e8e8e8; /* Highlight on row hover */
  }
}

.privacy-footer {
  margin-top: 10vh;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 300px;
  justify-content: flex-start;

  .checkbox-label {
    margin-left: 10px;
    margin-right: -240px;
    display: inline-block;
    color: black;
    font-family: "Arial";
    width: 200px;
    white-space: nowrap;
  }

  .checkbox-field {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Define the size and make it square */
    width: 20px;
    height: 20px;

    border: 1px solid #5c3030;
    border-radius: 1px;

    &:checked {
      background-color: #0669bf;
    }

    margin-right: 10px;
  }
}

/* error message for login/signup */
.errorMessage {
  color: #f40428;
  margin-bottom: 5px;
  margin-right: 20px;
  margin-top: 20px;
  font-family: "Arial";
  font-size: 16px;
}

/* TestRegistration.css - example CSS */
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* Use full viewport height */
  justify-content: center; /* Center the content vertically */
  background-color: #1e2246;
}

.signUpText {
  margin-top: 20vh;
  color: #ffffff;
  font-size: 5vh;
}

.testRegistrationForm {
  /* Styles for the form */
  font-size: 30px;
  // background-color: #1e2246;
  min-height: 100vh;
  justify-content: center;
  align-items: center;

  .registration-container {
    text-align: center;

    .emailLabel,
    .emailInput {
      margin-top: 20vh;
      margin-right: 1vh;
      border-radius: 8px;
      padding-top: 1vh;
      padding-bottom: 1vh;
      padding-left: 2vh;
      padding-right: 2vh;
    }
  }
  .submitButton {
    background-color: #007bff;
    border-radius: 8px;
    border: none;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 2vh;
    padding-right: 2vh;
    color: white;
    // margin-top: 5vh;
  }
}

//pw reset
.page-title {
  margin-bottom: 30px; /* Increase the bottom margin of the title */
  color: white;
}

.reset-form-container {
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #1e2246;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Arial;

  .form-container form {
    display: flex;
    flex-direction: column;
  }

  .input-field {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .submit-button {
    margin-left: 2vh;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .submit-button:hover {
    background-color: #0056b3;
  }

  .submit-button:disabled {
    background-color: #aec2e4; /* Lighter shade of the primary color */
    cursor: not-allowed; /* Indicates that the element is not interactive */
    opacity: 0.6;
  }

  .message {
    margin-top: 20px;
    font-size: 14px;
    color: #c7c4c4;
    font-size: 18px;
  }
}

.reset-password-container {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #1e2246;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Arial;
  color: #c7c4c4;

  .reset-password-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .reset-password-form {
    display: flex;
    flex-direction: column;
  }

  .reset-password-form div {
    margin-bottom: 15px;
  }

  .reset-password-form label {
    display: block;
    margin-bottom: 5px;
  }

  .reset-password-form input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .reset-password-form button {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }

  .reset-password-form button:hover {
    background-color: #0056b3;
  }

  .message {
    color: #d1d0d0; /* Default text color */
    margin-top: 3vh; /* Spacing below the message */
    font-size: 1rem; /* Default font size */
  }

  .error {
    color: #dadada; /* Error color, often a shade of red */
    font-weight: bold; /* Makes the error message more prominent */
  }
}

.centered-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Use fixed to make sure it covers the entire viewport */
  top: 0;
  left: 0;
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full viewport width */
  background-color: rgba(
    0,
    0,
    0,
    0.8
  ); /* Optional: background color with opacity */
  z-index: 1000; /* High z-index to make sure it's on top of other content */
}

.block-message {
  text-align: center; /* Center text within the block */
  width: 90%; /* Set a max width to the inner content */
  max-width: 600px; /* Optional: Constrain max width for better readability */
  padding: 20px; /* Padding for aesthetics */
  background: white; /* Background of the message, contrast with overlay */
  border-radius: 8px; /* Optional: rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: shadow for visual depth */
}

.block-message p {
  margin: 16px 0; /* Space out paragraphs */
}

.loading-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 1000; /* Make sure it's on top of other content */

.loading-popup {
  position: fixed; // Position fixed to cover the whole screen
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black background

  .loading-content {
    padding: 20px 40px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;

    p {
      margin: 0;
      font-size: 16px;
      color: #333;
    }
  }
}
}
